* {
    box-sizing: border-box;
}

@font-face {
    font-family: "Helvetica-Regular";
    src: url("/assets/fonts/Helvetica-Regular.woff2") format("woff2"),
        url("/assets/fonts/Helvetica-Regular.woff") format("woff");
}

@font-face {
    font-family: "Helvetica-Bold";
    src: url("/assets/fonts/Helvetica-Bold.woff2") format("woff2"),
        url("/assets/fonts/Helvetica-Bold.woff") format("woff");
    font-weight: bold;
}

:root {
    --color-primary: #05b0ff;
    --color-primary-rgb: 5, 176, 255;
    --color-tap-highlight: rgba(5, 176, 255, .16);
    --color-fab-background: #7ecd21;
    --color-fab-background-disabled: #808080;
    --color-fab-foreground: #fff;
    --color-dark-background: #1B1B1D;
    --color-dark-grey-background: #262626;
    --color-dark-text: #E3E2E6;
    --color-dark-text-help: #8E919A;
}

html,
body,
.root,
.root-router-host {
    height: 100%;
    user-select: none;
    -webkit-tap-highlight-color: var(--color-tap-highlight, rgba(5, 176, 255, .16));
}

.root>div:not(.loader-container) {
    display: flex;
    flex-direction: column;
    height: 100%;
}

[hidden] {
    display: none !important;
}

.file-field-hidden {
    display: none;
}

body {
    font-family: "Helvetica-Regular", sans-serif;
    font-size: 16px;
    color: #3f3f3f;
    background-color: #eee;
    overflow-x: hidden;
    margin: 0 auto;
}

body.app-type--webview {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

a {
    text-decoration: none;
}

button {
    background-color: transparent;
    border: none;
    padding: 0;
}

p {
    line-height: normal;
}

input {
    padding: 0;
    margin: 0;
}

.container {
    max-width: 1170px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}

.portraits-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-gap: 16px;

    @media screen and (max-width: 760px) {
        grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
    }
}

.portraits-item {
    border-radius: 12px;
    border: 1px solid #cdcdcd;
    overflow: hidden;
    position: relative;
    padding-top: 100%;

    button {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}

.btn-choose-head {
    width: 86px;
    height: 86px;
    border: solid 3px var(--color-primary, #05b0ff);
    border-radius: 50%;
    padding: 4px;
    position: absolute;
    top: 2px;
    left: 5px;
    z-index: 20;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
    span {
        font-size: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border: solid 3px var(--color-primary, #05b0ff);
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        right: 0;    
    }
}

.choose-items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: max-content;
}

.choose-item-container {
    // max-width: 25vh;
    width: 50%;
    position: relative;
    margin-bottom: 0;
    &::before {
        content: '';
        display: block;
        padding-top: 100%;
        width: 100%;
    }
}

.choose-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.active {
        border: 3px solid var(--color-primary, #05b0ff);
    }
}

.app-webview--ios {
    .start-page {
        @media all and (min-height: 720px) {
            @supports (padding-bottom: env(safe-area-inset-bottom)) {
                padding-bottom: calc(env(safe-area-inset-bottom) + 20px);
            }
        }
    }
}

.pt2 {
    padding-top: 2px;
}

.start-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 0;
    &-header {
        background-color: #fff;
        padding-top: 32px;
        padding-bottom: 24px;

        &.start-page-header-2in1 {
            padding-bottom: 40px;
        }

        @media all and (min-width: 460px) {
            padding-top: 34px;
            padding-bottom: 34px;
        }

        h2 {
            font-family: "Helvetica-Bold", sans-serif;
            font-weight: bold;
            font-size: 18px;
            text-align: center;
            line-height: 1.35;
            padding: 0 26px;
        }
    }
    .step-container {
        margin-bottom: 0;
    }
}

.step-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 13px;

    svg {
        width: 16px;
        fill: #868686;
        margin-right: 12px;
    }

    p {
        color: #868686;
    }
}

.templates-container {
    flex-shrink: 0;
    background-color: #fff;
    position: relative;
    padding-top: 2px;
    padding-bottom: 2px;

    &.templates-container-2in1 {
        position: absolute;
        z-index: 10;
        background: no-repeat;
        top: -32px;
        padding: 0;

        .btn-choice-template {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            margin-left: 8px;

            &.active {
                box-shadow: none;
                width: 64px;
                height: 64px;
            }
        }
    }

    .container {
        display: flex;
        align-items: center;
        overflow: auto;
        width: 100%;
        padding-left: 2px;
        padding-right: 2px;
    }

    button {
        flex-shrink: 0;
        overflow: auto;
        background-size: cover;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        font-size: 8px;
        text-align: center;
        color: #fff;
        
        span {
            position: relative;
            z-index: 20;
            margin-bottom: 4px;
            padding: 0 6px;
        }
    }

    .named {
        position: relative;
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 25px;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .btn-choice-gender {
        position: relative;
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-size: cover;
    
        svg {
            position: relative;
            z-index: 2;
            width: 12px;
            flex-shrink: 0;
            margin-bottom: 4px;
        }
    
        &:before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .5);
        }
    
        &-title {
            font-family: "Helvetica-Bold", sans-serif;
            font-weight: bold;
            font-size: 10px;
            text-transform: uppercase;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            z-index: 2;
    
            span {
                color: #fff;
            }
        }
    }

    p {
        display: none;
    }

    .btn-more {
        width: 144px;
        height: 56px;
        box-shadow: 0 0 25px 0 rgba(51, 51, 51, 0.3);
        background-color: #7fcd22;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.14;
        color: #fff;
        text-transform: uppercase;

        &_choice {
            box-shadow: none;
            width: 80px;
            height: 80px;
            border-radius: 0;
        }

        svg {
            width: 16px;
            margin-left: 8px;
            fill: #fff;
            flex-shrink: 0;
        }
    }

    .btn-more-square {
        width: 80px;
        height: 80px;
        box-shadow: none;
        border-radius: 0;
        display: inline-block;
        svg {
            width: 16px;
            margin-left: 8px;
            margin-right: 0;
            fill: #fff;
            flex-shrink: 0;
            position: relative;
            top: 2px;
        }

        &[disabled] {
            background-color: var(--color-fab-background-disabled);
        }

    }
}

.zodiac .btn-choice-template {
    height: 115px;
}

.templates-container-text {
    display: block;
    position: absolute;
    bottom: 92%;
    text-align: center;
    width: 100%;
    font-size: 14px;
}

.app-type--web {
    .templates-container .container {
        padding-bottom: 2px;
        &::-webkit-scrollbar {
            height: 8px;
            background-color: transparent;
        }
        &::-webkit-scrollbar-track {
            height: 8px;
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: #cdcdcd;
        }
    }
}

.right-position {
    display: flex;
    justify-content: flex-end;
    position: relative;
    h2 {
        width: calc(100% - 84px);
        text-align: right;

        @media all and (max-width: 359px) {
            font-size: 15px;
        }
    }
}

.btn-switch-to-male {
    background-image: url("/assets/images/bodychooser/male.png");

    .btn-choice-gender-title {
        color: #05b0ff;
    }

    svg {
        fill: #05b0ff;
    }
}

.btn-switch-to-female {
    background-image: url("/assets/images/bodychooser/female.png");

    .btn-choice-gender-title {
        color: #fe9e9e;
    }

    svg {
        fill: #fe9e9e;
    }
}

.app-webview--android {
    .btn-switch-to-male {
        .btn-choice-gender-title {
            color: #fff;
        }
        svg {
            fill: #fff;
        }
    }

    .btn-switch-to-female {
        .btn-choice-gender-title {
            color: #fff;
        }
        svg {
            fill: #fff;
        }
    }
}

.squidgame .btn-switch-to-male {
  background-image: url("/assets/images/squidgame/male.jpg");
}

.squidgame .btn-switch-to-female {
  background-image: url("/assets/images/squidgame/female.jpg");
}

.xmas21 .btn-switch-to-male {
  background-image: url("/assets/images/xmas21/male.png");
}

.xmas21 .btn-switch-to-female {
  background-image: url("/assets/images/xmas21/female.png");
}

.btn-choice-template {
    width: 80px;
    height: 80px;
    margin-left: 2px;

    &.active {
        box-shadow: inset 0 0 0 3px var(--color-primary, hwb(199 2% 0%));
    }

    &.loading {
        background-image: linear-gradient(90deg, rgba(var(--color-primary-rgb), 0), rgba(var(--color-primary-rgb), 0.14), rgba(var(--color-primary-rgb), 0));
        background-size: 40px 100%;
        background-repeat: no-repeat;
        background-position: left -40px top 0;
        animation: shine 1s ease infinite;
    }

    @keyframes shine {
        to {
            background-position: right -40px top 0;
        }
    }

    &--logotype {
        background-color: #fff;
        background-size: 75% !important;
        background-repeat: no-repeat;
        background-position: center;
    }

    &--bg-center {
        background-position: center;
    }

    &--fill-image {
        img {
            position: relative;
            display: block;
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
}

.btn-toggle-styles {
    width: 80px;
    height: 80px;
    margin-left: 2px;
    display: flex;
    align-items: center;
    padding: 0 6px 0 12px;
    text-align: left;
    background-color: var(--color-fab-background, #7ecd21);
    color: var(--color-fab-foreground, #fff);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.4;
    word-break: break-word;
}

.collage-container {
    display: flex;
    position: relative;
    flex-shrink: 1;
    height: 100%;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0 auto;
    .container {
        position: relative;
        height: 100%;
        width: 100%;
        padding: 0;
    }
}

.collage-container-content {
    background-color: #fff;
}

.footer-container {
    background-color: #fff;
    padding: 15px 0;
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 56px;
    }
    h2 {
        text-align: left;
        color: #999;
        line-height: 1.25;
        max-width: 200px;
    }
    .btn-done {
        position: static;
        flex-shrink: 0;
        box-shadow: 0 0 25px 0 rgba(51, 51, 51, 0.3);
        width: 56px;
        height: 56px;
    }
}

.collage {
    // width: 100%;
    // height: 100%;
    // max-height: 78.8vh;
    // position: absolute;
    // top: 0;
    // left: 0;
    // object-fit: contain;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.btn-done {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: var(--color-fab-background, #7ecd21);
    position: absolute;
    bottom: 16px;
    right: 16px;
    z-index: 100;
    svg {
        width: 28px;
        fill: var(--color-fab-foreground, #fff);
    }
}

.app-webview--android.app-theme--fab--rounded {
    .btn-done {
        bottom: 16px;
        right: 16px;
        width: 56px;
        height: 56px;
        border-radius: 16px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.15);
        svg {
            width: 24px;
        }
    }
}

/*loader*/
.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    p {
        color: #868686;
    }
    &.android {
        flex-direction: column;
        p {
            margin-bottom: 14px;
        }
    }
}

.loader-ios {
    display: flex;
    margin-right: 24px;
    .item-loader {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        animation: item-loader .9s linear infinite;
        margin: 0 6px;
    }

    .first-item-loader {
        background-color: #1db8ff;
    }

    .second-item-loader {
        background-color: #ffd300;
        animation: item-loader .9s linear infinite;
        animation-delay: .3s;
    }

    .third-item-loader {
        background-color: #fc4700;
        animation: item-loader .9s linear infinite;
        animation-delay: .6s;
    }

    @keyframes item-loader {
        0% {
            transform: scale(1, 1);
        }

        50% {
            transform: scale(1.8, 1.8);
        }
    }
}

.loader-android {
    width: 200px;
    height: 2px;
    position: relative;
    overflow: hidden;
    span {
        position: absolute;
        top: 0;
        height: 100%;
        background-color: var(--color-primary, #05b0ff);
    }
    span:first-child {
        width: 100%;
        opacity: 0.24;
    }
    span:last-child {
        width: 70px;
        animation: line-loader .9s linear infinite;
    }
    @keyframes line-loader {
        0% {
            left: -70px;
        }

        100% {
            left: calc(100% + 70px);
        }
    }
}

.app-theme--tooncoin {
    /*loader*/
    .loader-container {
        p {
            font-size: 16px;
            color: #999;
            margin-left: 0;
        }

        &.android {
            p {
                font-size: 14px;
                color: #79757F;
                margin-bottom: 12px;
            }
        }
    }

    .loader-ios {
        justify-content: center;
        margin: 0;
        margin-bottom: 12px;
    
        .item-loader {
            opacity: 1;
            box-shadow: 1px 1px #000;
        }
    
        .first-item-loader {
            background-color: #5f4da7;
        }
    
        .second-item-loader {
            background-color: #ffcb20;
        }
    
        .third-item-loader {
            background-color: #ff8db0;
        }
    
        @keyframes item-loader {
            0% {
                transform: scale(1, 1);
                opacity: 0;
            }
            80% {
                transform: scale(1.8, 1.8);
                opacity: 1;
            }
        }
    }

    .loader-android {
        span {
            background-color: #6250AA;
        }
    }

    &.app-webview--ios {
        .btn-done {
            box-shadow: 3px 3px 0 0 black;
            border: 1px solid #000;
            background-color: #ffcb20;
            
            svg {
                fill: #000;
            }
        }
    }

    &.app-webview--android {
        .btn-done {
            width: 56px;
            height: 56px;
            border-radius: 16px;
            border: none;
            box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .4);
            background-color: #6250aa;
            background-position: center;
            transition: background 0.8s;

            &:hover {
                background: #6250aa radial-gradient(circle, transparent 1%, #6250aa 1%) center/15000%;
            }

            &:active {
                background-color: #7264a9;
                background-size: 100%;
                transition: background 0s;
            }
            
            svg {
                fill: #fff;
            }
        }

        &.app-theme--dark {
            .btn-done {
                background-color: #cbbeff;

                svg {
                    fill: #331e79;
                }
            }
        }
    }
}

/*error block*/
.error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    p {
        color: #868686;
        text-align: center;
        margin-bottom: 16px;
    }
    button {
        font-size: 18px;
        font-weight: 600;
        color: var(--color-fab-foreground, #fff);
        height: 48px;
        flex-grow: 0;
        border-radius: 6px;
        text-transform: uppercase;
        background-color: var(--color-fab-background, #7ecd21);
        margin: 0 auto;
        padding: 0 10px;
        display: block;
    }
}

.shape-with-text-template {
    .form-container {
        padding: 24px;

        .form_group {
            margin-top: 32px;

            h3 {
                font-size: 14px;
                color: #868686;
                margin-bottom: 12px;
            }
        }

        .form_group-seed {
            display: flex;
            align-items: center;
            margin: 0;

            h3 {
                margin: 0;
            }

            span {
                font-size: 14px;
                margin-left: 4px;
            }

            input {
                font-size: 14px;
                color: #3f3f3f;
                margin: 0;
                margin-left: 4px;
                padding: 0;
                border: none;
            }
        }

        .form_radio_control {
            font-size: 12px;
            color: #3f3f3f;
            display: block;
            padding: 0;

            input {
                appearance: none;
            }

            span {
                position: relative;
                padding-left: 28px;

                &::after {
                    content: "";
                    display: block;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    background-color: #fff;
                    border: 2px solid #868686;
                    box-sizing: border-box;
                }
            }

            & > input:checked + span {
                &::after {
                    border: 5px solid #05b0ff;
                }
            }
        }

        .text_field {
            font-family: "Helvetica-Regular", sans-serif;
            font-size: 16px;
            color: rgb(63, 63, 63);
            line-height: 1.22;
            margin: 0 auto 24px;
            display: block;
            width: 100%;
            height: 78px;
            border: none;
            background: none;
            border-radius: 0;
            resize: none;
            padding: 0;
            overflow: auto;
        }
    }
}

.form_group-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.app-theme--dark .shape-with-text-template {
    .form_group {
        padding: 10px;
        border-radius: 4px;
        background: rgba(255, 255, 255, .1);
        margin-top: 24px;
    }
    .form-container .form_group-seed input {
        background: none;
    }
    .text_field,
    .form-container .form_group-seed span,
    .form-container .form_group-seed input {
        color: #f0f0f0;
    }
    .form_radio_control {
        color: #f0f0f0;
    }

    .form-container .form_group h3{
        color: var(--color-dark-text)
    }

    .btn-check {
        margin-top: 20px;
    }

    .form-container .text_field {
        margin-bottom: 16px;
    }

   .form-container .form_radio_control span::after {
        background-color: rgba(255, 255, 255, 0.1);
        border-color: var(--color-dark-text);
    }
}

.qrcode-container {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    padding-top: 12vh;

    input {
        margin: 20px auto 40px;
        display: block;
        width: 100%;
        max-width: 366px;
        border: none;
        border-bottom: 1px solid #3f3f3f;
        background: none;
        border-radius: 0;   
        padding: 4px 0;
    }

    .wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
    }
}

.app-theme--dark .qrcode-container {
    input {
        color: #f0f0f0;
    }
}

.banner {
    width: calc(100% + 20px);
    margin-left: -10px;
    height: 215px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    background-image:  url("/assets/images/banner/bg-waves@3x.png");
    background-repeat: repeat-x;
    background-size: contain;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;

    @media all and (max-width: 340px) {
        padding-left: 0;
        padding-right: 0;
    }

    &:hover {
        opacity: .8;
    }

    &::after {
        content: '';
        display: block;
        background-color: #fff;
        height: 90%;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -5;
    }

    &-left {
        position: relative;

        img {
            width: 93px;
        }

        svg {
            position: absolute;
            top: 40px;
            right: -24px;
            width: 77px;

            @media all and (max-width: 370px) {
                width: 64px;
            }
        }
    }

    &-right {
        img {
            max-width: 180px;
            width: 100%;
        }
    }

    &-bg {
        background-color: #fff;
        padding-top: 20px;
    }

    &-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
    }
    
    &-title {
        font-size: 14px;
        font-family: "Helvetica-Bold", sans-serif;
        color: #3f3f3f;
        text-align: right;
        max-width: 190px;
        line-height: 1.4;
        z-index: 10;
    
        span {
            color: #ffb801;
        }
    }

    &-footer {
        font-family: "Helvetica-Bold", sans-serif;
        font-size: 16px;
        color: #3f3f3f;
        position: absolute;
        bottom: 6px;
        right: 30px;
        background: none;
        height: auto;
        text-transform: inherit;
        z-index: 10;
        padding: 10px;
        margin: 0;
    }
}

.btn-check {
    position: absolute;
    bottom: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    padding: 0;
    border-radius: 50%;
    background-color: var(--color-fab-background, #7ecd21);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
    margin-left: auto;
    margin-top: 24px;

    svg {
        width: 16px;
        fill: var(--color-fab-foreground, #fff);
    }

    &[disabled] {
        background-color: #dddddd;

        span {
            color: var(--color-fab-foreground, #fff);
            opacity: 0.38;
        }

        svg {
            fill: #000;
            opacity: 0.1;
        }
    }
}

.btn-mask {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 16px;
    right: 28px;
    background-color: rgba(0, 0, 0, 0.4);

    svg {
        width: 20px;
    }
}

.btn-edit-mask {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 24px;
    right: 14px;
    z-index: 100;
    padding: 2px;
    box-sizing: border-box;

    svg {
        width: 100%;
        height: 100%;
        fill: #868686;
    }

    @media all and (min-width: 460px) {
        top: 34px;
    }
}

.app-webview--android.app-theme--fab--rounded {
    .btn-check {
        width: 56px;
        height: 56px;
        border-radius: 16px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.15);
        svg {
            width: 18px;
        }
    }
}

// --

.native-ad-overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;

    .loader-positioner {
        position: relative;
        width: 100%;
        min-height: 40px;
    }
}

// --

.mysecretidentity .btn-switch-to-female {
    background-image: url("/assets/images/bodychooser/female_mysecretidentity.png");
}

.mysecretidentity .btn-switch-to-male {
    background-image: url("/assets/images/bodychooser/male_mysecretidentity.png");
}

// --

.halloween-body-background {
    img {
        display: block;
        width: 80%;
        height: auto;
        margin: auto;
    }
}

.app-theme--dark {  
    background-color: var(--color-dark-background, #1B1B1D);

    .native-ad-overlay {
        background-color: var(--color-dark-background, #1B1B1D);
    }
    .footer-container {
        background-color: var(--color-dark-background, #1B1B1D);
    }

    .start-page-header,
    .templates-container,
    .collage-container-content {
        background-color: var(--color-dark-grey-background, #262626);
    }

    .footer-container {
        h2 {
            color: var(--color-dark-text-help, #8E919A);
        }
    }

    .start-page-header {
        h2 {
            color: var(--color-dark-text, #E3E2E6);
        }
    }

    .loader-container {
        p {
            color: var(--color-dark-text-help, #8E919A);
        }
    }

    .templates-container {
        .container {
            &::-webkit-scrollbar-thumb {
                background-color: var(--color-dark-text-help, #8E919A);
            }
        }
    }

    .btn-swap {
        background-color: var(--color-dark-grey-background, #262626);
        border: 2px solid var(--color-dark-text, #E3E2E6);

        img {
            width: 40px;
            height: 40px;
            border-color: var(--color-dark-grey-background, #262626);
        }

        svg {
            fill: var(--color-dark-text, #E3E2E6);
        }

        span {
            color: var(--color-dark-text, #E3E2E6);
        }
    }
}

.olympics22 {
    .templates-container {
        button {
            font-size: 10px;
            color: #3f3f3f;
            position: relative;
            
            span {
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 36px;
                height: 16px;
                background-color: rgba(255, 255, 255, .7);
                margin: 0;
                padding: 0;
            }

            &.active {
                span {
                    color: #fff;
                    background-color: var(--color-primary, #05b0ff);
                }
            }
        } 
    }
}

.valentine22 {
    .templates-container {
        height: 88px;
        background: no-repeat;
        display: flex;
        justify-content: center;
        max-width: 360px;
        margin: 24px auto 0;
        padding: 0;

        @media all and (max-width: 560px) {
            padding: 0 10px;
            box-sizing: border-box;
            margin-top: 0;
        }

        &_column {
            flex-direction: column-reverse;
            height: auto;
            max-width: 100%;
            padding-bottom: 24px;
            padding-top: 16px;

            &.pb-0 {
                padding-bottom: 0;

                .btn-more {
                    margin-bottom: 16px;
                }

                .btn-more-square {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.templates-container_column {
    .container {
        width: calc(100% + 20px);
        padding-left: 0;
        padding-right: 0;
        margin-left: -10px;
    }

    .btn-swap {
        width: auto;
        min-width: initial;
        align-self: center;
        position: relative;
        overflow: initial;
        box-shadow: none;
        border: solid 1px #e0e0e0;
        height: 56px;
        padding: 3px;
        margin-bottom: 26px;

        svg {
            width: 24px;
            margin-left: 8px;
            margin-right: 8px;
        }

        img {
            width: 48px;
            height: 48px;
            border: none;
        }

        span {
            display: none;
        }

        p {
            display: block;
            font-size: 8px;
            font-weight: 500;
            color: #999;
            text-transform: uppercase;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: calc(100% + 5px);
        }
    }

    .btn-choice-template {
        margin-left: 0;
        margin-right: 3px;
    }
}

.btn-swap {
    min-width: 258px;
    width: 100%;
    height: 48px;
    border-radius: 30px;
    background-color: #fff;
    box-shadow: 0 14px 14px 0 rgba(21, 22, 23, .1);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 2px;

    span {
        font-size: 14px;
        color: #3f3f3f;
        letter-spacing: 0.58px;
        text-transform: uppercase;
        padding: 0;
        padding-right: 10px;
        margin: 0;
        margin-left: 16px;
    }

    svg {
        fill: #3F3F3F;
        width: 27px;
        margin-left: 4px;
        margin-right: 4px;
    }

    img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        border: 1px solid #868686;
        object-fit: cover;
        object-position: top center;
    }

    .btn-images {
        display: flex;
        align-items: center;
    }
}
