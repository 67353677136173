.imageview-host {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: rgba(0, 0, 0, .1);

  .imageview-holder {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .imageview-holder,
  .imageview-image {
    animation: imageview-show 320ms;
  }
}

@keyframes imageview-show {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

// --

.dev-container {
  text-align: left;
  align-items: flex-start;
  padding: 20px;

  button {
    background: #99c;
    color: #fff;
    padding: 6px 12px;
    border-radius: 12px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
  }

  hr {
    width: 100%;
    height: 1px;
    background: #aaa;
    margin: 12px 0;
    border: 0;
  }
}